@import "src/styles/vars"

$square-size: 130px
.animated-background
  height: 100vh
  width: 100vw
  background: $semi-gray
  position: fixed
  isolation: isolate
  margin-right: -100vw
  overflow: hidden

  > .cursor
    background: $accent-color
    height: $square-size
    width: $square-size
    position: absolute
    pointer-events: none
    border-radius: $square-size/2
    mix-blend-mode: normal
    filter: blur(24px)

  > .rects
    position: relative
    height: 100vh
    width: 100vw
    z-index: 1
    background-image: url("../static/img/bg.svg")
    animation: anim 3.5s linear infinite

@keyframes anim
  from
    background-position-x: 0

  to
    background-position-x: -$square-size
